import axios from 'axios';
import { serialize } from "object-to-formdata";

import { debug } from '$lib/debug';
import { actions as loading_actions } from '$lib/stores/loading_store';
import { EndpointCollection } from '../endpoints';
import { api_client } from '../api_client';

export default async function(page_params, file: File, key_prefix?: string) {
  debug('upload_file', file.name);

  const { team_slug, parcel_path } = page_params;

  if (!team_slug || !parcel_path) {
    console.warn('Trying to upload file without team_slug or parcel_path');
    return;
  }


  loading_actions.start_loading("upload_file", file.name);
  const key = key_prefix ? `${key_prefix}/${file.name}` : file.name;
  const type = file.type;
  let result: boolean;

  try {
    // Obtain presigned URL
    let { url, method, request_data, response_body: presigned_post } = EndpointCollection.client.presigned_upload_url;
    request_data = { team_slug, parcel_path, key, type };
    const res = await api_client({ url, method, data: request_data });
    presigned_post = res.data;

    // Post data to presigned URL
    const form_data = serialize(presigned_post.fields);
    form_data.append("file", file);

    await axios.post(presigned_post.url, form_data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    result = true;
  } catch (error) {
    console.error(error);
    console.warn('Failed to upload file', key);
  }
  loading_actions.stop_loading("upload_file", file.name);
  return result;
}
