// 
// This file can import other stores, but should be the only store to do so
// 
import { get, writable } from 'svelte/store';
import type { Writable } from 'svelte/store';

import { RunState } from '$lib/types/types';
import { debug } from '$lib/debug';

import { debug_flags } from '$lib/stores/flags';

export let show_files: Writable<boolean> = writable(false);
export let show_config: Writable<boolean> = writable(false);
export let show_result: Writable<boolean> = writable(false);


// Decide which components to show/hide, depending on state (show_files, show_config, show_result)
export function configure_shows(state) {
  debug('configure shows for state', state);
  let next_show_config = false;
  let next_show_files = false;
  let next_show_result = false;

  switch (state) {
    case RunState.not_configured:
      next_show_config = true;
      next_show_files = false;
      next_show_result = false;
      break;
    case RunState.configured:
      next_show_config = true;
      next_show_files = false;
      next_show_result = false;
      break
    case RunState.running:
      next_show_config = false;
      next_show_files = true;
      next_show_result = false;
      break
    case RunState.success:
      next_show_config = false;
      next_show_files = true;
      next_show_result = true;
      break
    case state === RunState.failed:
      next_show_config = false;
      next_show_files = true;
      next_show_result = true;
      break
    case RunState.unknown:
      next_show_config = false;
      next_show_files = false;
      next_show_result = false;
      break
    default:
      next_show_config = false;
      next_show_files = false;
      next_show_result = false;
      break;
  }


  if (get(debug_flags).power) {
    next_show_files = true;
  }

  show_config.set(next_show_config);
  show_files.set(next_show_files);
  show_result.set(next_show_result);

  debug('shows', { show_config: get(show_config), show_files: get(show_files), show_result: get(show_result) })
};
