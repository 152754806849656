import { get } from 'svelte/store';
import localforage from 'localforage';

export async function set_cache(key, value) {
  await localforage.setItem(key, value);
}

export async function get_cache(key) {
  return await localforage.getItem(key);
}

export async function clear() {
  await localforage.clear();
}

export function clear_cache() {
  clear();
  console.warn('Cleared IDB file cache');
}

import { debug_flags } from '$lib/stores/flags';
import { browser } from '$app/env';
import type { CacheProperties } from '$lib/types/types';
import { debug } from '$lib/debug';
import presigned_download_url from './requests/download_url';
import axios from 'axios';

export async function cache_or_network_download_content({ team_slug, parcel_path, key }: CacheProperties): Promise<undefined | any> {

  const cache_active = Boolean(browser && get(debug_flags).cache);
  debug('cache: cache_active', cache_active);

  const full_key = generate_cache_key(team_slug, parcel_path, key);

  // Strategy: check cache first, return if hit found
  if (cache_active) {

    const found = await get_cache(full_key);
    if (found) {
      return found;
    }

    debug('cache: No cache hit, processed with network request');
  }

  // If cache not active, or no cache hit, retrieve from network
  let response_body: any;

  try {
    const presigned_url = await presigned_download_url(team_slug, parcel_path, key);
    if (!presigned_download_url) return response_body;

    const res = await axios({ url: presigned_url });
    response_body = res.data;

    if (response_body && cache_active) {
      await set_cache(full_key, response_body);
    }
  } catch (error) {
    debug('Problem retrieving file content for', key);
  }
  return response_body;
}


function generate_cache_key(team_slug: string, parcel_path: string, cache_key: string) {
  const joiner = "__";
  return [team_slug, parcel_path, cache_key].map(i => i ? i : '--').join(joiner);
}