import { actions as loading_actions } from '$lib/stores/loading_store';
import { debug } from '$lib/debug';
import type { RemoteFileInfo, } from '$lib/types/types';
import { EndpointCollection } from '../endpoints';
import { api_client } from '../api_client';

export default async function(team_slug: string, parcel_path: string, file: RemoteFileInfo,) {
  debug('delete_file', file);

  loading_actions.start_loading("delete_file", file.key);
  let { url, method, request_data, response_body } = EndpointCollection.client.delete_file;
  request_data = { team_slug, parcel_path, key: file.key };

  try {
    const res = await api_client({ url, method, data: request_data });
    response_body = res.data;
  } catch (error) {
    console.warn('Did not delete file');
  }

  loading_actions.stop_loading("delete_file", file.key);
  return response_body;
}