import { actions as loading_actions } from "$lib/stores/loading_store";
import { EndpointCollection } from "../endpoints";
import { api_client } from "../api_client";

export const list_files = async function(team_slug: string, parcel_path: string) {
  loading_actions.start_loading("list_files");
  let { url, method, request_data, response_body } = EndpointCollection.client.list_files;
  request_data = { team_slug, parcel_path };

  try {
    const res = await api_client({
      url,
      method,
      data: request_data,
    });
    response_body = res.data;
  } catch (error) {
    console.warn("No files retrieved");
  }

  loading_actions.stop_loading("list_files");
  return response_body;
}
