import { actions as loading_actions } from '$lib/stores/loading_store';
import { cache_or_network_download_content } from '../cache';

export default async function(page_params, key: string) {
  loading_actions.start_loading("retrieve_file", key);

  const { team_slug, parcel_path } = page_params;

  if (!team_slug || !parcel_path) {
    console.warn('Trying to download_file_content without team_slug or parcel_path');
    return;
  }

  let content;

  try {
    // Get content from presigned URL
    content = await cache_or_network_download_content({ team_slug, parcel_path, key })
  } catch (error) {
    console.error(error);
  }

  loading_actions.stop_loading("retrieve_file", key);
  return content;
}
