import { derived, get, writable } from 'svelte/store';
import type { Writable } from 'svelte/store';

import { last } from 'lodash-es';

import { debug } from '$lib/debug';
import { delete_file, download_file_content, list_files, upload_file } from '$lib/network';
import type { AlgoUseWithAlgo, KeyPrefix, RemoteFileInfo, } from '$lib/types/types';
import { FileFilterType, RunState } from '$lib/types/types';
import { filter_files } from '$lib/network';
import type { definitions } from '$lib/types/supabase';
import { all_true, all_false, all_null } from '$lib/utils';
import { check_has, check_has_result_files } from '$lib/network';
import { config } from '$lib/config';
import { configure_shows, show_config } from './app_state';
import { supabase } from '$lib/data/supabase';


export const active_parcel_files: Writable<RemoteFileInfo[]> = writable(null);
export const algo_use: Writable<definitions['algo_uses']> = writable(null);
export const algorithm: Writable<definitions['algos']> = writable(null);
export const params: Writable<object> = writable(null);

export const active_state: Writable<RunState> = writable(null);
export const active_show_files: Writable<RemoteFileInfo[]> = writable(null);
export const active_tab_file_key: Writable<RemoteFileInfo["key"]> = writable(null);

export const request_files = derived(
  [active_parcel_files],
  ([$active_parcel_files]) => {
    return filter_files($active_parcel_files, FileFilterType.request);
  },
)

export const request_data_files = derived(
  [active_parcel_files],
  ([$active_parcel_files]) => {
    return filter_files($active_parcel_files, FileFilterType.request_data);
  },
)

export const result_files = derived(
  [active_parcel_files],
  ([$active_parcel_files]) => {
    return filter_files($active_parcel_files, FileFilterType.result);
  },
)

export const result_data_files = derived(
  [active_parcel_files],
  ([$active_parcel_files]) => {
    return filter_files($active_parcel_files, FileFilterType.result_data);
  },
)

export const actions = {
  show_file: (file: RemoteFileInfo) => {
    const got = get(active_show_files) || [];
    const already_got = got && got.filter(f => f.key === file.key).length;

    // TODO: Check if this is a circular import
    show_config.set(false);

    if (!already_got) {
      active_show_files.set([...got, file]);
    }
    active_tab_file_key.set(file.key);
  },
  remove_from_show_files: async (file: RemoteFileInfo) => {
    const got = get(active_show_files) || [];
    active_show_files.set(got?.filter(f => f.key !== file.key));
    active_tab_file_key.set(last(get(active_show_files))?.key);
  },
};

/**
 * Retrieve and set as many of these as found, reset the rest unless flag set: 
 *  - `active_parcel_files` list, 
 *  - `algorithm` (from `fn_slug` file), 
 *  - `params` (from `request.json` file)
 * 
 *  - `active_state` (from `active_parcel_files`)
 * @param  {string} team_slug
 * @param  {string} parcel_path
 * @param  {} recalculate_state=true
 * @returns Promise
 */
export async function retrieve_all(page_params) {
  debug('retrieve_all (aka everything)');

  // Reset everything
  reset_file_states();

  // Check for required params
  const { team_slug, parcel_path } = page_params;

  if (!team_slug || !parcel_path) {
    console.warn('Trying to retrieve_files without team_slug or parcel_path');
    return;
  }

  // List files
  const got_files = await list_files(team_slug, parcel_path);
  active_parcel_files.set(got_files);

  // Get algo_use and params, etc from algo
  const got_slug = await download_file_content(page_params, config.files.fn_slug_key);

  if (got_slug) {
    const { data, error } = await supabase.from<AlgoUseWithAlgo>('algo_uses').select(`*, algos ( * )`).eq("slug", got_slug).limit(1);
    if (data) {
      algo_use.set(data[0]);
      algorithm.set(data[0].algos); // Assuming the query above returns the 'algos' property
    }
  }

  // Get params
  const got_params = await download_file_content(page_params, config.files.request_json_key);
  if (got_params) params.set(got_params);

  // Get record
  const { data, error } = await supabase
    .from<definitions["runs"]>("runs")
    .select("*")
    .eq("parcel_path", parcel_path)
    .eq("team_slug", team_slug)
    .order("created_at", { ascending: false });

  let run: definitions['runs'];
  if (error) return console.error(error);
  if (data.length > 1) console.warn(`Received more than one matching record for - have you manually re-run this parcel?`, team_slug, parcel_path);
  run = data[0];

  update_app_state(get(active_parcel_files), run);
  configure_shows(get(active_state));
}

export async function retrieve_files(page_params) {
  debug('retrieve_files');

  // Check for required params
  const { team_slug, parcel_path } = page_params;

  if (!team_slug || !parcel_path) {
    console.warn('Trying to retrieve_files without team_slug or parcel_path');
    return;
  }

  // List files
  const got_files = await list_files(team_slug, parcel_path);
  active_parcel_files.set(got_files);
}

export function reset_file_states() {
  active_parcel_files.set(null);
  algo_use.set(null);
  algorithm.set(null);
  params.set(null);
  active_state.set(null);
  configure_shows(get(active_state));
}

export const effects = {
  upload_files: async (page_params, files: File[] | FileList, key_prefix?: KeyPrefix) => {
    const { team_slug, parcel_path } = page_params;

    if (!team_slug || !parcel_path) {
      console.warn('Trying to upload file without team_slug or parcel_path');
      return;
    }

    let success: RemoteFileInfo[] = [];
    let failed: File[] = [];

    for (const file of Array.from(files)) {
      const res = await upload_file(page_params, file, key_prefix);
      if (res) {
        success.push({ key: null, filename: file.name, });
      } else {
        failed.push(file);
      }
    };

    if (failed.length) {
      console.warn('Failed to upload: ' + failed.map(f => f.name).join(', '));
    }

    await retrieve_files({ team_slug, parcel_path });

    return { success, failed };
  },
  delete_file: async (page_params, file: RemoteFileInfo): Promise<void> => {
    debug('delete_file', file);
    const { team_slug, parcel_path } = page_params;

    if (!team_slug || !parcel_path) {
      console.warn('Trying to delete_file without team_slug or parcel_path');
      return;
    }

    await delete_file(team_slug, parcel_path, file);
    await retrieve_all(page_params);
  },
};

export function update_app_state(files: RemoteFileInfo[], run?: definitions['runs']) {
  const state = calculate_state(files, run);
  debug('active_state output:', state);
  active_state.set(state);
}

export function calculate_state(files: RemoteFileInfo[], run?: definitions['runs']): RunState {
  let state: RunState;

  if (run && run.batch_status === 'FAILED') {
    return RunState.failed;
  }

  const has_job_id = check_has(files, config.files.job_id_key);
  const has_fn_json = check_has(files, config.files.fn_slug_key);
  const has_request_json = check_has(files, config.files.request_json_key);
  const has_result_files = check_has_result_files(files);

  const local_result_data_files = filter_files(files, FileFilterType.result_data);
  const result_data_files_length = local_result_data_files.length;

  if (all_null(files)) { // Similar to testing for parcel_path being null
    state = RunState.unknown;
  } else if (all_true(has_result_files) && result_data_files_length === 0) {
    state = RunState.failed;
  } else if (all_true(has_result_files)) {
    state = RunState.success;
  } else if (all_true(has_job_id) && all_false(has_result_files)) {
    state = RunState.running;
  } else if (all_true(has_fn_json, has_request_json) && all_false(has_job_id)) {
    state = RunState.configured;
  } else {
    state = RunState.not_configured;
  }
  return state;
}

export const can_edit = derived(active_state, ($active_state) => $active_state !== RunState.running && $active_state !== RunState.success);
