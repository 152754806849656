import { actions as loading_actions } from '$lib/stores/loading_store';
import { EndpointCollection } from '../endpoints';
import { api_client } from '../api_client';

export default async function(team_slug, parcel_path: string, key: string) {
  loading_actions.start_loading("retrieve_file", key);
  let { url, method, request_data, response_body } = EndpointCollection.client.presigned_download_url;
  request_data = { team_slug, parcel_path, key };

  try {
    const res = await api_client({ url, method, data: request_data });
    response_body = res.data;
  } catch (error) {
    console.warn('Could not get presigned_url for ', key);
  }

  loading_actions.stop_loading("retrieve_file", key);
  return response_body;
}
