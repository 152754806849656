import type { RemoteFileInfo } from '$lib/types/types';
import { FileFilterType, } from '$lib/types/types';
import { config } from '$lib/config';

export const check_has = (files: RemoteFileInfo[], key: string): boolean | null => {
  if (files === null) {
    return null;
  }
  if (!files?.length) {
    return false;
  }
  const filenames = files.map(i => i.key)
  return filenames.includes(key);
}

export const check_has_result_files = (files: RemoteFileInfo[]): boolean | null => {
  if (files === null) {
    return null;
  }
  if (!files?.length) {
    return false;
  }
  const result_files_count = files?.map(i => i.key).filter(i => i.startsWith('result')).length || 0;
  return result_files_count > 0;
};


/**
 * Filter files with given filter_type
 * @param  {RemoteFileInfo[]} files
 * @param  {FileFilterType} filter_type
 * @returns RemoteFileInfo
 */
export const filter_files = (files: RemoteFileInfo[], filter_type: FileFilterType): RemoteFileInfo[] => {
  if (!files) {
    return null;
  } else if (filter_type === FileFilterType.all) {
    return files;
  } else if (filter_type === FileFilterType.request_data) {
    return files?.filter((i) =>
      i.key.startsWith(config.files.request_path_prefix) && ![config.files.fn_slug_key, config.files.request_json_key].includes(i.key)
    );
  } else if (filter_type === FileFilterType.request) {
    return files?.filter((i) =>
      i.key.startsWith(config.files.request_path_prefix)
    );
  } else if (filter_type === FileFilterType.result) {
    return files?.filter((i) =>
      i.key.startsWith(config.files.result_path_prefix)
    );
  } else if (filter_type === FileFilterType.result_data) {
    return files?.filter((i) =>
      i.key.startsWith(config.files.result_path_prefix) && ![config.files.log_file_key, config.files.meta_file_key].includes(i.key)
    );
  } else {
    return files;
  }
}
